@import "./App.scss";
.emphasis {
  font-weight: 600;
  letter-spacing: 4px;
  background: linear-gradient(to right, blue, red);
}
#about-me {
	font-family: "Inconsolata", monospace;
	font-size: 0.8rem;
	display: flex;
	justify-content: space-between;
	text-shadow: 1px 1px 5px black;
	flex-direction: column;
	@media (min-width: $md) {
		flex-direction: row;
	}
}
#about-image {
	width: auto;
	max-height: 30vh;
	margin: 0 auto;
	display: block;
	margin-bottom: 1rem;
	@media (min-width: $md) {
		width: 100%;
		margin: 0;
		height: auto;
		max-height: none;
	}
}
.about-text {
	@media (min-width: $md) {
		flex-basis: 55%;
	}	
	align-items: center;
	display: flex;
	text-align: justify;
	padding: 0 1rem;
}

.image-wrapper {
	flex-basis: 35%;
}
.image-wrapper img {
	width: 100%;
}
.disclaimer {
	font-size: 0.7rem;
	padding: 1rem;
	text-align: center;
	max-width: 500px;
	margin: 0 auto;
}
.center-header {
	text-align: center;
	font-size: 2rem;
	color: white;
}
.section {
	margin-bottom: 2rem;
}
.skill-list {
	display: flex;
	margin: 0 -0.5rem;
	max-width: 1000px;
	margin: 0 auto;
	flex-direction: column;
	@media (min-width: $md) {
		flex-direction: row;
	}
}
.skill-column {
	flex-basis: 50%;
	padding: 0 0.5rem;
}
.skill-row {
	display: flex;
	align-items: center;
}
.skill-level {
	flex-basis: 45%;
	display: flex;
	max-width: 220px;
}
.skill-name {
	flex-basis: 35%;
	text-align: right;
	margin: 0.5rem 0.5rem 0.5rem 0;
	font-family: "Inconsolata", monospace;
	text-shadow: 1px 1px 5px #333;
	font-size: 1.1rem;
}
#mask-0 {
	animation-name: example;
	animation-duration: 1.2s;
	animation-fill-mode: backwards;
	animation-timing-function: ease;
}
#mask-1 {
	animation-name: example;
	animation-duration: 1.2s;
	animation-delay: 0.1s;
	animation-fill-mode: backwards;
	animation-timing-function: ease;
}
#mask-2 {
	animation-name: example;
	animation-duration: 1.2s;
	animation-delay: 0.2s;
	animation-fill-mode: backwards;
	animation-timing-function: ease;
}
#mask-3 {
	animation-name: example;
	animation-duration: 1.2s;
	animation-delay: 0.3s;
	animation-fill-mode: backwards;
	animation-timing-function: ease;
}
#mask-4 {
	animation-name: example;
	animation-duration: 1.2s;
	animation-delay: 0.4s;
	animation-fill-mode: backwards;
	animation-timing-function: ease;
}
@keyframes example {
  from {transform: scale(0.001);}
  to {transform: scale(1);}
}
.project-grid {
	display: grid;
	grid-template-columns: 1fr;
	margin-top: 1rem;
	@media (min-width: 700px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.art-block {
		font-size: 2rem;
		text-align: justify;
		background: linear-gradient(to right, blue, red);
		height: 70%;
		display: flex;
		align-items: center;
		div {
			padding: 0.5rem 1rem;
		}
	}
}
.project-detail {
	margin: 0 0.5rem;
	@media (min-width: $xs) {
		margin: 0;
	}
	h2 {
		color: white;
		font-size: 1.5rem;
	}
	.content {
		display: flex;
		flex-direction: column;
	}
	.project-description {
		font-family: "Inconsolata", monospace;
		font-size: 0.9rem;
		max-width: 800px;
		margin: 0 auto;
		text-shadow: 1px 1px 5px #333;
		a {
			background: yellow;
			color: black;
			text-shadow: none;
			&:hover {
				background: white;
			}
		}
	}
}
.carousel-wrapper {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 30vh;
	max-height: 30vh;
	.image-carousel {
		display: flex;
		flex-wrap: nowrap;
		position: absolute;
		transition: transform 0.5s;
		height: 100%;
		.slide {
			width: 100%;
			flex-shrink: 0;
			img {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
		}
	}
}
.carousel-button {
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 0 0.5rem;
	font-size: 2rem;
	cursor: pointer;
	text-shadow: 1px 1px 5px #333;
	font-weight: 600;
	&.back {
		left: 0rem;
	}
	&.forward {
		right: 0rem;
	}
	&:hover {
		color: yellow;
	}
	div {
		display: inline-block;
	}
}
.carousel {
	position: relative;
	margin: 1rem auto;
	display: flex;
	width: 100%;
	@media (min-width: 900px) {
		width: 800px;
	}
	@media (min-width: 1200px) {
		width: 1000px;
	}
}

#contact {
	.contact-text {
		font-family: "Inconsolata", monospace;
		text-align: center;
		max-width: 800px;
		margin: 0 auto;
		padding: 1rem;
	}
	padding-bottom: 5rem;
}