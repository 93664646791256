$xs: 500px;
$sm: 720px;
$md: 920px;
$lg: 1200px;
$xl: 1440px;

.App {
  margin: 0 auto;
  width: 100%;
}
#root {
  z-index: 3;
  position: relative;
}
body {
  background: black;
  color: white;
  font-family: 'VT323', monospace;
}
html {
  font-size: 24px;
}
@media (min-width: 500px) {
  .App {
    width: 90%;
  }
}
@media (min-width: 720px) {
  .App {
    width: 640px;
  }
}
@media (min-width: 920px) {
  .App {
    width: 900px;
  }
}
@media (min-width: 1200px) {
  .App {
    width: 1100px;
  }
}
@media (min-width: 1440px) {
  .App {
    width: 1440px;
  }
}
.link-list {
  display: flex;
  padding: 0.5rem;
  background-color: white;
}
.page-link {
  max-width: 100px;
  flex-basis: 15%;
  margin: 0 1rem;
  text-transform: uppercase;
  font-weight: 600;
}
.inline-link {
  background: yellow;
  color: black;
  text-shadow: none;
  font-weight: 600;
  letter-spacing: 4px;
}
.inline-link:hover {
  background: white;
  color: black;
}
.page-link a {
  color: black;
}
.page-link a:hover {
  background: black;
}
h1 {
  margin: 1rem 0;
}
h2 {
    margin: 0;
    color: yellow;
    font-size: 1.25rem;
}
.center {
    text-align: center;
    text-decoration: none;
}
.shift {
    margin-left: 25px;
    text-decoration: none;
    color: white;
    margin-right: 30px;
}
.right {
    text-align: right;
    text-decoration: line-through
}
.img-responsive {
    height: 70%;
    width: 100%;
    object-fit: cover;
    background: black;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 0.5rem;
  @media (min-width: 500px) {
    grid-template-columns: 1fr 1fr;
    padding: 0;
  }
  @media (min-width: 920px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0;
  }
}
.project {
  height: 320px;
  padding: 0 0.5rem;
}
a {
  text-decoration: none;
  color: white;
}
a:hover {
  color: yellow;
}
.col {
    min-height: 250px;
}
.col:hover {
    color: yellow;
}
html, body {
    max-width: 100%;
    overflow-x: hidden;
}
button.active {
    background: black;
    color: yellow;
}
button.focus {
    
}
button.active:focus {
    color: yellow;
}
button.active:hover {
    color: red;
    background: black;
}
.btn-group {
    color: black;
    background: white;
}
.btn:hover {
    color: yellow;
    background: black;
}
button {
    color: black;
    background: white;
}
#filters {
    margin-bottom: 15px;
}
#yearCol {
    padding: 0;
}
#filterCol {
    padding: 0;
}
#tag_filter {
    float: right;
}
#filterLabel {
    pointer-events: none;
}
.page-container {
  padding-top: 1rem;
  min-height: 70vh;
}
#works {
  margin: 0 -0.5rem;
}
.headshot {
  max-width: 50%;
  height: auto;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}
@media (min-width: 320px) {
    #filters {
        display: none;
    }
}
@media (min-width: 500px) {
    #filters {
        display: block;
    }
}

#bio_text {
    font-family: 'Inconsolata', monospace;
    font-size: 0.8rem;
    line-height: 1.2;
    text-shadow: 1px 1px 5px #333;
}

canvas {
  image-rendering: optimizeSpeed;            
  image-rendering: -moz-crisp-edges; 
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  image-smoothing-enabled: false;
}
.footer {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 4rem;
  .social-logo {
    width: 50px;
    height: 50px;
    margin: 0 0.5rem;
    background: white;
    display: inline-block;
    cursor: pointer;
    &.gh {
      -webkit-mask: url("/logos/gh_logo.svg") no-repeat center;
      mask: url("/logos/gh_logo.svg") no-repeat center;
      mask-size: contain;
    }
    &.insta {
      -webkit-mask: url("/logos/insta.svg") no-repeat center;
      mask: url("/logos/insta.svg") no-repeat center;
      mask-size: contain;
    }
    &.linkedin {
      -webkit-mask: url("/logos/linkedin.svg") no-repeat center;
      mask: url("/logos/linkedin.svg") no-repeat center;
      mask-size: contain;
    }
    &:hover {
      background: yellow;
      animation: bounce 0.12s ease-in;
      animation-fill-mode: forwards;
    }
  }
}
@keyframes bounce {
  0% {transform: scale(1);}
  70% {transform: scale(1.17);}
  100% {transform: scale(1.1);}
}